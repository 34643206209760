import { UnitAccessCard, UnitConstructionUpdate } from './api-interfaces';
import {
  AboutInfo,
  Common,
  FilterValue,
  OptionValue,
  SectionTag,
} from './common.interface';
import { DestinationProject } from './destination.interface';

export enum TagType {
  FEATURED = 'featured',
  HOT = 'hot',
}

export interface UnitInterface extends Common {
  project: {
    id: number;
    name: string;
    destination: FilterValue;
  };
  unit_type: FilterValue;
  unit_number: string;
  name: string;
  price: number;
  currency: string;
  bedrooms: number;
  bathrooms: number;
  unit_size: number;
  is_resale: boolean;
  is_wishlisted: boolean;
  cover_image: string;
  hover_image: string;
  tags: string[];
  is_user_saved: boolean;
  masterplan_image: string;
  built_up_area?: number;
  project_phase: { id: number; name: string };
  is_commercial: boolean;
}

export enum CountriesEnum {
  EGYPT = 'egypt',
  OMAN = 'oman',
  MONTENEGRO = 'montenegro',
}
export interface UnitDestination extends Common {
  slug: string;
  name: string;
  welcome_description: string;
  cover_image: string;
  country: {
    id: number;
    name: string;
    slug: CountriesEnum;
  };
  account_number: string;

  masterplan_description: string;
  brochure: string;
}
export interface UnitProject extends DestinationProject {
  destination: UnitDestination;
  description: string;
  min_bedrooms: number;
  max_bedrooms: number;
  sales_offer: string;
  floorplan_page_on_sales_offer: number;
  paymentplan_page_on_sales_offer: number;
  floor_number: number;
  unit_images_pages_on_sales_offer: string;
  brochure: string;
  slug: string;
}
export interface UnitFacility {
  title: string;
  description?: string;
  icon: string;
}

export interface UnitAddOn extends Partial<UnitConstructionUpdate> {
  title: string;
  price: number;
  image: string;
  name: string;
  description?: string;
  gallery?: string[];
  available_for_purchase?: boolean;
  is_purchasable?: boolean;
  unit?: UnitInterface;
  metadata?: {
    specs: AboutInfo[];
  };
}

export interface UnitMasterPlan extends Common {
  id: number;
  description: string;
  image: string;
  title: string;
  total_area: string;
  metadata: {
    general: {
      name: string;
      value: string;
      type: string;
      unit: string;
      slug: string;
      is_predefined: boolean;
    }[];
  };
}

export interface UnitType {
  id: number;
  name: string;
  image: string;
  slug: string;
}

export interface PriceValue {
  min: number;
  max: number;
  currency: string;
}

export interface PriceSlider {
  price: PriceValue;
  minRange: number;
  maxRange: number;
}

export interface UnitDetails extends Common {
  id: number;
  unit_number: string;
  name: string;
  account_statement: string;
  project: UnitProject;
  unit_type: UnitType;
  gallery: string[];
  price: number;
  currency: string;
  area_image: string;
  total_area: string;
  built_up_area: string;
  garden_area: string;
  roof_area: string;
  terrace_area: string;
  net_area: string;
  view: string;
  direction: string;
  construction_status: string;
  finishing_status: string;
  bedrooms: number;
  bathrooms: number;
  unit_size: number;
  is_resale: boolean;
  is_wishlisted: boolean;
  cover_image: string;
  hover_image: string;
  video: string;
  brochure: string;
  facilities: UnitFacility[];
  addons: UnitAddOn[];
  tags: SectionTag[];
  masterplans: UnitMasterPlan[];
  masterplan_image: string;
  masterplan_description: string;
  delivery_date?: number;
  video_thumbnail: string;
  is_user_saved?: boolean;
  floor_number: number;
  unit_images_pages_on_sales_offer: string;
  is_available: boolean;
  access_card: UnitAccessCard;
  construction_updates: UnitConstructionUpdate[];
  live_url: string;
  sale: UnitPayment;
  dollar_price: number;
  design_type: string;
  project_phase: { id: number; name: string };
  is_commercial: boolean;
}

export interface UnitReservationDetails {
  bedrooms: number;
  bathrooms: number;
  cover_image: string;
  gallery: string[];
  id: number;
  is_user_saved?: boolean;
  price: number;
  project: {
    id: number;
    description: string;
    destination: UnitDestination;
    name: string;
    terms_conditions: string;
    slug: string;
  };
  reservation_fees: number;
  unit_number: string;
  unit_size: number;
  unit_type: FilterValue;
  currency: string;
  is_commerial: boolean;
}

export interface UnitCompareInterface {
  id: number;
  facilities: UnitFacility[];
  project: UnitProject;
  unit_type: FilterValue;
  price: number;
  currency: string;
  bedrooms: number;
  bathrooms: number;
  unit_size: number;
  is_resale: boolean;
  is_wishlisted: boolean;
  cover_image: string;
  hover_image: string;
  tags: string[];
  master_plan_images: string[];
  masterplan_image: string;
  built_up_area?: number;
  name: string;
  floor_number: number;
  construction_status: string;
  finishing_status: string;
  garden_area: string;
  total_area: string;
  masterplans: UnitMasterPlan[];
  gallery: string[];
  is_commercial: boolean;
}

export interface UnitPayment extends Common {
  down_payment: number;
  period: number;
  discount: number;
  maintenance_fees: number;
  club_fees: number;
  before_delivery: number;
  after_delivery: number;
}

export enum ReservationStateEnum {
  INITIAL_STATE = 'initial-state',
  PAYMENT_PLAN = 'payment-plan',
  PAY_RESERVATION_FEE = 'pay-reservation-fee',
  PAY_RESERVATION_FEE_DONE = 'pay-reservation-fee-done',
  YOUR_INFO = 'your-info',
  RESERVATION_FORM = 'reservation-form',
  RESERVATION_FORM_DONE = 'reservation-form-done',
}

export const InstallmentFrequencyMapper: Record<string, string> = {
  1: 'Month',
  3: 'Quarter',
  12: 'Year',
};

export interface PaymentPlanDetails {
  due_date: string;
  installment_amount: number;
  installment_duration_in_months: number;
  installment_duration_in_years: number;
  installment_frequency: number;
  is_installment: boolean;
  order: number;
  payment_amount: number;
  payment_percent: number;
  payment_type: string;
  total_number_of_installments: number;
}

export interface UnitPaymentTerms {
  after_delivery_installment_amount: number;
  before_delivery_installment_amount: number;
  installment_amount?: number;
  clubhouse_fees: number;
  clubhouse_fees_installment_amount: number;
  clubhouse_fees_installment_count: number;
  currency: string;
  discount_amount: number;
  discount_percent: number;
  downpayment_amount: number;
  downpayment_percent: number;
  installment_durationin_months: number;
  installment_durationin_years: number;
  installment_frequency: number;
  maintenance_fees: number;
  paymentplan_id: string;
  paymentplan_name: string;
  price_after_discount: number;
  delivery_amount: number;
  delivery_percent: number;
  total_number_of_installments: number;
  total_number_of_installments_after_delivery: number;
  total_number_of_installments_before_delivery: number;
  unit_installment_before_delivery_per_frequency: number;
  unit_installment_after_delivery_per_frequency: number;
  total_unit_price: number;
  unit_id: string;
  unit_price: number;
  first_installment_percent: number;
  first_installment_amount: number;
  prereservation_percent: number;
  prereservation_amount: number;
  payment_plan_details?: PaymentPlanDetails[];
  vat_percentage?: number;
  vat_amount?: number;
  price_after_vat?: number;
}

export interface CompareUnitPaymentCalculatorInterface {
  unitId: number;
  displayedDetails: UnitPaymentTerms;
  installments: number[];
  paymentTerms: UnitPaymentTerms[];
  selectedPaymentTerms: UnitPaymentTerms;
}

export interface UnitReservationNationality {
  nationality_id: string;
  nationality_name: string;
}

export interface UnitReservationCountry {
  country_id: string;
  country_name: string;
}

export interface UnitReservationCity {
  city_id: string;
  city_name: string;
}

export interface UnitReservationOccupation {
  label: string;
  code: number;
}

export interface UnitReservationConnection {
  connection_id: string;
  connection_name: string;
}

export interface UserReservationIds {
  customerId: string | undefined;
  leadId: string | undefined;
}

export interface AddDraftSaleParams {
  customer_source_id: string;
  lead_source_id: string;
  broker_id: string | null;
  referral_unit_name: string;
  referral_number: string;
  paymentplan_source_id: string;
  reservation_amount: string;
}

export interface ReserveTransactionCodeParams {
  currency_code: string;
  currency_rate: number;
  reservation_amount: number | null;
  reservation_payment_method: string;
  transaction_code: PaymentMethods;
}
export enum PaymentMethods {
  BANK_TRANSFER = '127990001',
  CREDIT_DEBIT_CARD = '127990002',
}

export interface ReferralDetails {
  unitName: string;
  mobileNumber: string;
}

export interface ResaleFormData {
  customer_source_id: string;
  asking_price: number;
  currency_source_id: string;
  portal_comments: string;
  preferred_communication_method: string;
}

export interface NewContractingDetails {
  club_fees: number;
  new_contract_sign_date: number;
  delivery_after_x_months: number;
  delivery_installment_date: number;
  maintenance_fees: number;
  maintenance_fees_amount: number;
  new_selling_price: number;
  currency: string;
  new_contracting_id: string;
}

export enum SalePaymentType {
  DOWNPAYEMENT = 100000000,
  DELIVERY = 100000002,
  INSTALLMENT = 100000003,
}

export interface SaleInstallment {
  payment_value: number;
  payment_date: number;
  payment_name: string;
  clubhouse_fees: number | null;
  maintenance_fees: number | null;
  currency: string;
  total_payment_value: number;
  rates_percent: number | null;
  paid: boolean;
  type: string;
}
export interface SaleInstallmentsResponse {
  down_payment_paid: number;
  down_payment_unpaid: number;
  total_installments_count: number;
  paid_installments_count: number;
  unpaid_installments_count: number;
  total_installments_paid: number;
  total_installments_unpaid: number;
  total_installments_values: number;
  total_clubhouse_fees: number;
  total_maintenance_fees: number;
  installments: SaleInstallment[];
  deliveryInstallments: SaleInstallment[];
  reservationInstallments: SaleInstallment[];
}

export interface MontenegroOnlinePaymentBody {
  cancel_url: string;
  fail_url: string;
  shopping_cart_id: string;
  shop_id: string;
  signature: string;
  success_url: string;
  amount: number;
}
